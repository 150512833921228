// ! should not contain any external libraries or chakra styles
// ! as this error page is being shown before those libraries are loaded
import { colors } from '@waitroom/config';
import { errorService } from '@waitroom/error-service';
import { useEffect } from 'react';
import {
  deleteCache,
  onLoadError,
  unregister,
} from '../../../services/serviceWorker';
import PageLoader from '../../Loader/Page';

const buttonStyle = {
  padding: '1rem 2rem',
  minWidth: '220px',
  fontSize: '1.1rem',
  fontWeight: 600,
  border: 0,
  borderRadius: '3rem',
  background: colors.red[800],
  color: '#fff',
  cursor: 'pointer',
};

export const onError = (err: Error) => {
  errorService.report(err);
  onLoadError(err);
};

const FullscreenError = () => {
  useEffect(() => {
    deleteCache();
    unregister();
  }, []);

  return (
    <PageLoader containerStyles={{ textAlign: 'center' }}>
      <h1 style={{ fontSize: '2.5rem' }}>Something went wrong!</h1>
      <button
        type="button"
        style={buttonStyle}
        onClick={() => window.location.reload()}
      >
        Refresh page
      </button>
    </PageLoader>
  );
};

export default FullscreenError;
