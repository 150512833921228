import { EventType } from '@waitroom/analytics';
import { ReportHandler } from 'web-vitals';
import { analyticsService } from './services/analytics';

export const sendToAnalytics: ReportHandler = ({ id, name, value }) => {
  analyticsService.track(
    EventType.WebVitals,
    {
      action: name,
      value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
      label: id, // id unique to current page load
      nonInteraction: true, // avoids affecting bounce rate
    },
    {
      'Mixpanel-Dev': false,
      'Mixpanel-Prod': false,
    },
  );
};

const reportWebVitals = (onPerfEntry?: ReportHandler): void => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};

export default reportWebVitals;
