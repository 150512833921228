import { windowDefined } from '@waitroom/utils';
import Bowser from 'bowser';
import { DeviceType } from '../../constants/DeviceType';
import { SupportedBrowsers } from '../../constants/supportedBrowsers';

export const fcWidgetService = {
  isEnabled: (): boolean => windowDefined() && !!window.fcWidget,
  open: (): void => {
    if (window.fcWidget) window.fcWidget.open();
  },
};

export const browser: Bowser.Parser.Parser | undefined =
  windowDefined() && window.navigator && window.navigator.userAgent
    ? Bowser.getParser(window.navigator.userAgent)
    : undefined;

export const deviceType =
  browser !== undefined
    ? (browser.getPlatformType(true) as DeviceType)
    : undefined;
export const isMobileDevice =
  deviceType === DeviceType.MOBILE || deviceType === DeviceType.TABLET;

export type BrowserSupportOptions = {
  browsers?: SupportedBrowsers;
  devices?: DeviceType[];
};
const support = () => {
  const supported: Record<string, boolean> = {};
  const isDevice = (d: DeviceType): boolean =>
    browser !== undefined && d === deviceType;
  const isBrowser = (browsers: SupportedBrowsers): boolean =>
    (browser !== undefined && browser.satisfies(browsers)) ?? false;
  const isSupported = (
    key: string,
    { browsers, devices }: BrowserSupportOptions,
  ): boolean => {
    supported[key] =
      supported[key] ??
      ((!browsers || isBrowser(browsers)) &&
        (!devices || devices.every(isDevice)));
    return supported[key];
  };
  return {
    device: deviceType,
    isHandheld: isDevice(DeviceType.MOBILE) || isDevice(DeviceType.TABLET),
    supported,
    isDualStreamSupported:
      !!browser && browser.getBrowserName(true).toLowerCase() !== 'safari',
    isSupported,
    isDevice,
    isBrowser,
    osName: browser?.getOSName(true),
  };
};
export const supportService = support();
