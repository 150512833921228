import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { reducers } from '@waitroom/common';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export function createReducer(injectedReducers?: Record<string, Reducer>) {
  return combineReducers({
    ...reducers,
    ...injectedReducers,
  });
}
