import { errorService } from '@waitroom/error-service';
import branch, { BranchError } from 'branch-sdk';
import { envs } from '../envs';

const key = envs.VITE_BRANCH_API_KEY;
const enabled = key?.length > 0;

export const branchService = {
  enabled,
  fingerprint: {
    get: undefined as Promise<string | undefined> | undefined,
  },
  init: function init() {
    if (!this.enabled) return;
    try {
      branch.init(key, undefined, (err: BranchError) => {
        if (err) {
          this.enabled = false;
          //errorService.report(err); // temporary muted error
        }
      });
    } catch (_error) {
      this.enabled = false;
    }
  },
  initFingerprint: function initFingerprint() {
    const fp = new Promise<string | undefined>((resolve) => {
      if (!this.enabled) return resolve('');
      try {
        branch.getBrowserFingerprintId((error, data) => {
          if (error) errorService.report(error);
          return resolve(data || undefined);
        });
      } catch (error) {
        return resolve(undefined);
      }
    });
    this.fingerprint.get = fp;
    return fp;
  },
};
