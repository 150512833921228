import { AnalyticsBrowser, Integrations } from '@segment/analytics-next';
import { EventPayloads, MeetingLifecycleEventTypes } from '@waitroom/analytics';
import { config } from '../../config';
import { getFingerPrint } from '../../utils/finger-print';
import { envs } from '../envs';
import { MeetingType, Session } from '@waitroom/models';
import { getCurrentMeetingType } from '@waitroom/common';

const writeKey = envs.VITE_SEGMENT_ID;
const enabled = !!writeKey;
const client = new AnalyticsBrowser();

export const analyticsService = {
  init: async () => {
    if (!enabled) return;
    client.load({ writeKey });
    getFingerPrint().then((fingerprint) => {
      client.identify(fingerprint, {
        version: config.version,
      });
    });
  },
  user: async (
    props: Record<string, unknown> = {},
    integrations: Integrations = { All: true },
  ) => {
    const fingerprint = await getFingerPrint();
    client.identify(
      fingerprint,
      {
        ...props,
        version: config.version,
      },
      { integrations },
    );
  },
  track: async <T extends keyof EventPayloads>(
    event: T,
    props: EventPayloads[T],
    integrations: Integrations = { All: true },
  ) => {
    if (!enabled) return;
    return client.track(event, props, { integrations });
  },
  trackWithSession: async (
    event: MeetingLifecycleEventTypes,
    session?: Session,
  ) => {
    if (!enabled || !session) return;
    const {
      sessionID: meetingId,
      isViewerAccessRestricted,
      sessionSettings: {
        summaAI: aiEnabled,
        enableRecording: recordingEnabled,
        slackChannelID,
        preferredLanguages,
        timeLimit,
      },
      startTimestamp,
    } = session;
    const meetingType = getCurrentMeetingType(session);
    analyticsService.track(event, {
      meetingId: meetingId,
      isPrivate: isViewerAccessRestricted ?? true,
      recordingEnabled: recordingEnabled ?? false,
      aiEnabled: aiEnabled ?? false,
      slackEnabled: !!slackChannelID,
      meetingType,
      slotLengthSeconds:
        meetingType === MeetingType.PartyMode ? undefined : timeLimit,
      isInstant: !startTimestamp,
      languages: preferredLanguages,
    });
  },
  page: async (
    name: string,
    props: Record<string, unknown> = {},
    integrations: Integrations = { All: true },
  ) => {
    if (!enabled) return;
    return client.page(name, props, { integrations });
  },
  logout: async () => {
    return client.reset();
  },
};
