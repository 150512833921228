import { Role as f, rolesWithId as S, VideoFileExtension as R, SocialKeys as E } from "@waitroom/models";
import d from "dayjs";
const A = (t) => t != null, h = (t) => typeof t == "function", B = (t, ...e) => e.some((n) => typeof t === n), M = (t, e) => e in t, j = (t, e) => t instanceof e, T = (...t) => (e) => t.reduceRight((n, o) => o(n), e), F = (...t) => (e) => t.reduce((n, o) => o(n), e), U = (t) => (...e) => Promise.resolve(t(...e)), b = (t) => (e) => (...n) => {
  try {
    return t(...n);
  } catch (o) {
    return e ? e(o) : o;
  }
}, C = (t, e = 2, n = 100) => {
  const o = async (r = 1) => {
    try {
      return await t();
    } catch (s) {
      if (r >= e)
        throw s;
      return new Promise(
        (i) => setTimeout(() => i(o(r + 1)), n * r)
      );
    }
  };
  return o();
}, Y = (t) => (e) => (...n) => t(n) ? e(n) : void 0, D = () => typeof window < "u", L = (t, e) => t == null ? void 0 : t.reduce((n, o) => e ? e(n, o) : (n[String(o)] = o, n), {}), _ = (t, e) => t ? (Array.isArray(e) ? e : [e]).every((o) => t.includes(o)) : !1, v = (t, e) => t ? (Array.isArray(e) ? e : [e]).some((o) => t.includes(o)) : !1, P = (t, e, n = ", ") => {
  if (!t)
    return "";
  const o = h(e) ? e : (r) => String(r[e] ?? "");
  return t.map(o).filter(Boolean).join(n);
}, V = (t, e, n) => {
  if (!t)
    return "";
  const o = h(e) ? e : (u) => String(u[e] ?? ""), r = t.map(o).filter(Boolean);
  if (r.length <= 1)
    return r.join(", ");
  const s = [...r], i = s.pop();
  return `${s.join(", ")} ${n} ${i}`;
};
function x(t, e) {
  return [
    ...t.filter((n) => e.indexOf(n) === -1),
    ...e.filter((n) => t.indexOf(n) === -1)
  ];
}
function z(t) {
  const e = Math.floor(Math.random() * t.length);
  return t[e];
}
function H(t, e) {
  const n = {}, o = [];
  for (const r of t) {
    const s = r[e];
    n[s] || (n[s] = !0, o.push(r));
  }
  return o;
}
const a = [
  "B",
  "KB",
  "MB",
  "GB",
  "TB",
  "PB",
  "EB",
  "ZB",
  "YB"
], l = 1024, W = (t, e = 2) => {
  if (t === 0)
    return "0 B";
  const n = e < 0 ? 0 : e, o = Math.floor(Math.log(t) / Math.log(l));
  return `${parseFloat((t / l ** o).toFixed(n))} ${a[o]}`;
}, q = (t, e = "B", n = "MB", o = 2) => {
  if (!t || e === n)
    return t;
  const r = o < 0 ? 0 : o, s = a.indexOf(n), i = a.indexOf(e);
  if (s < 0 || i < 0)
    return t;
  const u = s - i;
  return parseFloat((t / l ** u).toFixed(r));
}, c = (t) => (e) => e ? `${t}[${e}]` : `${t}`, G = (t, e) => t ? t.map(
  (n) => c(n)(n in S ? e : void 0)
) : void 0, J = c(f.HOST), Z = c(f.GUEST), Q = c(f.VIEWER), X = c(
  f.SESSION_PRODUCER
), K = c(f.SESSION_OWNER), k = c(
  f.SESSION_INFO_VIEWER
), tt = c(f.ACTIVE_STREAMS), et = (t) => (e) => `space:${c(t)(e)}`, O = (t) => {
  const [, e] = t.split(":");
  if (!e)
    return;
  const [n, o] = e.split("[");
  return {
    role: n,
    id: o && o.replace("]", "")
  };
}, nt = (t) => (O(t) || {}).id, m = /:00/, ot = (t, e, {
  separator: n = " — ",
  date: o = "dddd, MMM D, YYYY",
  time: r = "h:mm A"
} = {}) => {
  const s = d(t).format(`${o}${n}${r}`).replace(m, ""), i = d(e).format(r).replace(m, "");
  return `${s} - ${i}`;
}, p = (t) => {
  const n = ~~((t < 0 ? 0 : t) / 1e3), o = ~~(n / 60), r = ~~(o / 60);
  return {
    days: ~~(r / 24),
    hours: r % 24,
    minutes: o % 60,
    seconds: n % 60
  };
}, g = {
  days: "d",
  hours: "h",
  minutes: "m",
  seconds: "s"
}, $ = Object.keys(g), rt = (t, e = {}) => {
  const n = p(t), o = { ...g, ...e };
  return $.reduce(
    (r, s) => n[s] > 0 ? `${r}${n[s]}${o[s]} ` : r,
    ""
  ).trim();
}, st = (t) => typeof t == "number" ? t * 1e3 : t, it = (t, e) => {
  const n = d(t), o = [`FREQ=${e}`];
  return e === "weekly" ? o.push(`BYDAY=${n.format("dd")}`) : e === "monthly" ? o.push(`BYMONTHDAY=${n.format("D")}`) : e === "yearly" && o.push(`BYMONTH=${n.format("M")};BYMONTHDAY=${n.format("D")}`), o.join(";").toUpperCase();
}, ct = (t, e = ":") => {
  const { days: n, hours: o, minutes: r, seconds: s } = p(t), i = [];
  let u = o;
  return n > 0 && (u += n * 24), u > 0 ? (i.push(String(u)), i.push(String(r).padStart(2, "0"))) : i.push(String(r)), i.push(String(s).padStart(2, "0")), i.join(e);
}, ut = (t, e, n, o) => {
  e.forEach((r) => {
    t.addEventListener(r, n, o);
  });
}, ft = (t, e, n, o) => {
  e.forEach((r) => {
    t.removeEventListener(r, n, o);
  });
}, dt = (t) => window.btoa(unescape(encodeURIComponent(t))), at = (t) => decodeURIComponent(escape(window.atob(t))), lt = (t, e, n, o = {}) => t.reduce((r, s) => {
  const i = n[s] ?? e[s];
  return r[s] = s in o || i === void 0 ? i : String(i), r;
}, {});
function mt(t) {
  try {
    return JSON.parse(t), !0;
  } catch {
    return !1;
  }
}
function ht(t, e) {
  if (!t)
    return e;
  try {
    return JSON.parse(t);
  } catch {
    return e;
  }
}
const pt = (t = 0, e = Number.MAX_SAFE_INTEGER) => Math.floor(Math.random() * (e - t + 1)) + t, gt = (t, e, n = "+ ") => t > e ? `${n}${e}` : `${t}`, St = (t) => t !== void 0 && Object.prototype.toString.call(t) === "[object Object]", w = (t) => Object.keys(t);
function Rt(t, ...e) {
  return e.length ? e.reduce((o, r) => {
    const { [r]: s, ...i } = o;
    return i;
  }, t) : t;
}
function Et(t, e, n) {
  if (!t)
    return n;
  const r = (typeof e == "string" ? e.split(".") : e).reduce(
    (s, i) => s && s[i] !== void 0 ? s[i] : void 0,
    t
  );
  return r !== void 0 ? r : n;
}
const Ot = (t, e) => t ? t[e] : void 0;
function $t(t, e) {
  return !t || !e || Object.entries(e).forEach(([n, o]) => {
    t[n] = o;
  }), t;
}
function N(t, e) {
  return !t || !e || Object.entries(e).forEach(([n, o]) => {
    typeof o == "object" && !Array.isArray(o) && o !== null && t[n] !== null ? N(t[n], o) : t[n] = o;
  }), t;
}
function wt(t, e, n) {
  return w({ ...t, ...e }).reduce((o, r) => ((n ? n(t[r]) === n(e[r]) : t[r] === e[r]) || (o[r] = e[r]), o), {});
}
const Nt = (t, e = R.HLS) => {
  for (let n = t.length - 1; n > -1; n -= 1)
    if (t[n].endsWith(e))
      return t[n];
  return t[0];
}, yt = (t, e, n = "...") => !t || t.length <= e ? t : `${t.substring(0, e)}${n}`, It = (t) => !!t && t.trim() !== "", At = (t) => !!t && String(t).toLowerCase() !== "false" && t !== "0", Bt = ({
  text: t,
  /** Lenght of the step for reducing the size */
  lengthStep: e,
  /** Size reduction for each step  */
  sizeStep: n = 0.1,
  /** Minimum size */
  min: o = 0.5
}) => {
  const r = (t || "").length, s = 1 - Math.floor(r / e) * n;
  return s < o ? o : s;
}, Mt = `  
`, jt = ({
  params: t,
  relative: e = !1
} = {}) => {
  const n = new URL(window.location.href);
  return t && Object.keys(t).forEach(
    (o) => n.searchParams.set(o, String(t[o]))
  ), e ? `${n.pathname}${n.search}` : n.href;
}, Tt = ({
  relative: t = !1,
  queryString: e = !0
} = {}) => {
  const n = new URL(window.location.href);
  return e || (n.search = ""), t ? `${n.pathname}${n.search}` : n.href;
}, Ft = (t) => t ? /(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/i.test(
  t
) : !1, Ut = (t) => !t || t.indexOf("://") === -1 && t.indexOf("//") !== 0, bt = (t, e) => {
  const n = new URL(t);
  return Object.keys(e).forEach(
    (o) => n.searchParams.set(o, String(e[o]))
  ), n.href;
};
function Ct(t) {
  return t && t.replace(/(wss?:\/\/|wss?:)/g, "").replace("/socket", "").replace("/websocket", "");
}
const Yt = (t) => t ? t == null ? void 0 : t.reduce((e, n) => (n.platform === "email" || (e[n.platform] = n.url), e), {}) : void 0, Dt = (t) => t ? E.reduce((e, n) => {
  const o = t[n];
  return !o || n === "email" || e.push({
    platform: n,
    url: o
  }), e;
}, []) : void 0;
export {
  bt as addQueryParams,
  L as arrayToMap,
  ut as bindEvents,
  jt as buildCurrentUrl,
  Bt as calcResponsiveSize,
  T as compose,
  _ as containsAll,
  v as containsAny,
  q as convertBytes,
  at as decode,
  N as deepUpdate,
  wt as diff,
  x as difference,
  rt as durationToFormat,
  dt as encode,
  Yt as flattenSocials,
  W as formatBytes,
  ot as fromTo,
  Et as get,
  tt as getActiveStreamsIdRoleName,
  Ot as getByKey,
  et as getChannelName,
  Tt as getCurrentUrl,
  Ct as getDomainKeyFromUrl,
  ct as getFormattedLength,
  Z as getGuestIdRoleName,
  J as getHostIdRoleName,
  nt as getIdFromChannelName,
  c as getIdRoleName,
  z as getRandomItem,
  pt as getRandomNumber,
  Nt as getRecordingURL,
  G as getRolesNames,
  k as getSessionInfoViewerIdRoleName,
  K as getSessionOwnerIdRoleName,
  X as getSessionProducerIdRoleName,
  Q as getViewerIdRoleName,
  Y as ifThen,
  A as isDefined,
  h as isFunction,
  j as isInstanceOf,
  mt as isJsonString,
  It as isNotEmpty,
  St as isObject,
  Ut as isRelativeUrl,
  M as isType,
  B as isTypeof,
  Ft as isValidUrl,
  P as joinOn,
  V as joinWithAnd,
  w as keysOf,
  Mt as mdNewline,
  lt as mergeValues,
  l as multiplier,
  Rt as omit,
  O as parseChannel,
  ht as parseJson,
  p as parseMiliseconds,
  F as pipe,
  U as promisify,
  C as retry,
  a as sizes,
  At as toBoolean,
  st as toEpoch,
  gt as toLimit,
  it as toRRule,
  yt as truncate,
  b as tryCatch,
  ft as unbindEvents,
  Dt as unflattenSocials,
  H as uniqBy,
  $t as update,
  D as windowDefined
};
