import { initConfig as initCommon } from '@waitroom/common';
import { initConfig as initApi } from '@waitroom/common-api';
import { errorService } from '@waitroom/error-service';
import { storageKeys } from '@waitroom/models';
import { config } from '.';
import { sentryService } from '../services/sentry';
import { storageService } from '../services/storage';
import { getFingerPrint } from '../utils/finger-print';
import { getClientDetails } from './clientDetails';
const getFP = getFingerPrint();

sentryService.init();

// init api config
initApi({
  baseURL: config.domains.wormhole,
  version: 'v1.0',
  getAuthToken: () => storageService.get(storageKeys.TOKEN) || '',
  getFingerPrint: getFP,
  getClientDetails,
});

// init common config
initCommon({
  domains: {
    trixta: config.trixta.domain,
    mars: config.domains.default,
    luxor: config.domains.luxor,
    punjab: config.domains.punjab,
    wormhole: config.domains.wormhole,
  },
  featureFlags: config.featureFlags,
  getFingerPrint: getFP,
  getClientDetails: getClientDetails(),
  storage: storageService,
  dateTime: config.dateTime,
  reportError: errorService.report,
  session: {
    maxSpeakers: config.session.maxSpeakers,
  },
});
