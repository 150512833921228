class T extends Error {
  constructor(r, e = "") {
    super(e), this.code = r, this.message = e, this.name = this.constructor.name, typeof Error.captureStackTrace == "function" ? Error.captureStackTrace(this, this.constructor) : this.stack = new Error(e).stack;
  }
}
var o = /* @__PURE__ */ ((E) => (E.GOOGLE = "google", E.FACEBOOK = "facebook", E.TWITTER = "twitter", E.APPLE = "apple", E.REGISTER = "register", E.LOGIN = "login", E))(o || {}), _ = /* @__PURE__ */ ((E) => (E.FACEBOOK = "facebook", E.INSTAGRAM = "instagram", E.LINKEDIN = "linkedin", E.REDDIT = "reddit", E.RESTREAM = "restream", E.YOUTUBE = "youtube", E.TIKTOK = "tiktok", E.TWITTER = "twitter", E.TWITCH = "twitch", E.WHATSAPP = "whatsapp", E))(_ || {});
const R = {
  twitter: {
    name: "Twitter",
    color: "#1DA1F2",
    secondaryColor: "#FFF"
  },
  facebook: {
    name: "Facebook",
    color: "#1877F2",
    secondaryColor: "#FFF"
  },
  instagram: {
    name: "Instagram",
    color: "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%)",
    secondaryColor: "#FFF"
  },
  linkedin: {
    name: "LinkedIn",
    color: "#0A66C2",
    secondaryColor: "#FFF"
  },
  youtube: {
    name: "YouTube",
    color: "#FF0000",
    secondaryColor: "#FFF"
  },
  tiktok: {
    name: "TikTok",
    color: "#FFFFFF",
    secondaryColor: "#000"
  },
  reddit: {
    name: "Reddit",
    color: "#FF5700",
    secondaryColor: "#FFF"
  },
  restream: {
    name: "Restream",
    color: "#000000",
    secondaryColor: "#FFF"
  },
  twitch: {
    name: "Twitch",
    color: "#6441A4",
    secondaryColor: "#FFF"
  },
  whatsapp: {
    name: "WhatsApp",
    color: "linear-gradient(36deg, rgba(45,182,64,1) 0%, rgba(88,207,99,1) 100%)",
    secondaryColor: "#FFF"
  }
}, l = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null
}, Symbol.toStringTag, { value: "Module" }));
var S = /* @__PURE__ */ ((E) => (E.PAUSED = "paused", E.RUNNING = "running", E.LOADING = "loading", E))(S || {});
const U = {
  UNKNOWN: 1001,
  GENERAL: 1002,
  AVATAR_UPLOAD_ERROR: 1010,
  SESSION_ACCESS_DENIED: 1011,
  SESSION_IS_FULL: 1012,
  SESSION_ACCESS_UNAUTHORIZED: 2076,
  SESSION_ACCESS_ALREADY_REQUESTED: 2088,
  SESSION_ACCESS_ALREADY_APPROVED: 2084,
  SESSION_ACCESS_DOESNT_EXIST: 2086,
  // agora errors
  LOCAL_STREAM_AUDIENCE_ERROR: 4e3,
  LOCAL_STREAM_MISSING_UID: 4001,
  CREATE_MIC_CAM_TRACK_ERROR: 4002,
  CREATE_LOCAL_STREAM_ERROR: 4003,
  PUBLISH_UNDEFINED_LOCAL_STREAM_ERROR: 4004,
  PUBLISH_LOCAL_STREAM_UNDEFINED_CLIENT_ERROR: 4005,
  PUBLISH_LOCAL_STREAM_TRACKS_ERROR: 4006,
  SUBSCRIBE_TO_USER_VIDEO_TRACK_ERROR: 4007,
  REMOTE_STREAM_SET_PLAYBACK_DEVICE_ERROR: 4008,
  MISSING_AGORA_APP_ID: 4009,
  MISSING_TOKEN_ID: 4010,
  MISSING_CHANNEL_ID: 4011,
  CLIENT_JOIN_ERROR: 4012,
  MISSING_STREAM_VIDEO_TRACK: 4013,
  MISSING_STREAM_AUDIO_TRACK: 4014,
  TOGGLE_BEAUTY_EFFECT_ERROR: 4015,
  CLOSE_MIC_VIDEO_TRACK_ERROR: 4016,
  LOAD_DEVICES_ERROR: 4017,
  GRANT_DEVICES_ACCESS_ERROR: 4018,
  TEST_DEVICES_NO_CAMERA_ERROR: 4019,
  TEST_DEVICES_CREATE_CAMERA_TRACK_ERROR: 4020,
  FETCHING_STREAM_TOKEN_ERROR: 4021,
  TOGGLE_MIC_ERROR: 4022,
  TOGGLE_CAMERA_ERROR: 4023,
  SET_CAMERA_CONFIGURATION_ERROR: 4024,
  SET_CAMERA_DEVICE_ERROR: 4025,
  SET_MICROPHONE_DEVICE_ERROR: 4026,
  SET_REMOTE_VIDEO_STREAM_TYPE_ERROR: 4027,
  SUBSCRIBE_TO_USER_AUDIO_TRACK_ERROR: 4028,
  CREATE_CLIENT_ERROR: 4029,
  SET_STREAM_FALLBACK_OPTION_ERROR: 4030,
  GENERAL_AGORA_ERROR: 4031,
  PUBLISH_AUDIENCE_LOCAL_STREAM_ERROR: 4032,
  CLIENT_JOIN_UNCOMPLETED_ERROR: 4033,
  NOT_COMPATIBLE_ERROR: 4034,
  MISSING_STREAM_VIDEO_AUDIO_TRACK: 4035,
  CLIENT_NOT_INITIALIZED: 4036,
  MISSING_DEFAULT_DEVICE: 4037,
  CREATE_MIC_CAM_TRACK_FALLBACK_ERROR: 4038,
  ERROR_REFRESHING_LIVE_STREAM: 4039,
  ERROR_STOPPING_LIVE_STREAM: 4040,
  MISSING_VIDEO_AUDIO_STORE: 4041,
  FAILED_TO_PLAY_DEVICES: 4042,
  REMOTE_CLIENT_JOIN_ERROR: 4043,
  LOCAL_CLIENT_JOIN_ERROR: 4044,
  CLIENTS_JOIN_ERROR: 4045,
  SET_ENABLED_ERROR: 4046,
  SET_MUTED_ERROR: 4047,
  MISSING_SCREEN_SHARE_STORE: 4048,
  CREATE_SCREEN_SHARE_STREAM_ERROR: 4049,
  SCREEN_SHARE_STREAM_MISSING_UID: 4050,
  LOCAL_STREAM_IS_NOT_AVAILABLE: 4051,
  LOCAL_STREAM_VIDEO_TRACK_IS_NOT_AVAILABLE: 4052,
  MISSING_MICROPHONE_ONLY_STORE: 4053,
  TRACKS_NOT_PROPERLY_RELEASED_ERROR: 4054,
  BROADCAST_STORE_ERROR: 4055,
  INIT_TRACK_EFFECTS_ERROR: 4056
}, P = {
  [_.FACEBOOK]: R.facebook,
  [_.LINKEDIN]: R.linkedin,
  [_.RESTREAM]: R.restream,
  [_.YOUTUBE]: R.youtube,
  [_.TWITCH]: R.twitch,
  custom: { name: "Custom RTMP", color: "#FFF", secondaryColor: "#1E2431" }
};
var A = /* @__PURE__ */ ((E) => (E.one = "one", E.group = "group", E))(A || {}), N = /* @__PURE__ */ ((E) => (E[E.portrait = 0] = "portrait", E[E.landscape = 1] = "landscape", E))(N || {}), t = /* @__PURE__ */ ((E) => (E.HLS = ".m3u8", E.MPD = ".mpd", E))(t || {}), C = /* @__PURE__ */ ((E) => (E.NONE = "none", E.PAUSED = "paused", E.STOPPED = "stopped", E.RUNNING = "running", E.HALTED = "halted", E))(C || {}), D = /* @__PURE__ */ ((E) => (E.DISABLED = "DISABLED", E.PENDING = "PENDING", E.READY = "READY", E))(D || {}), a = /* @__PURE__ */ ((E) => (E[E.NONE = 0] = "NONE", E[E.DAILY = 1] = "DAILY", E[E.WEEKLY = 2] = "WEEKLY", E[E.MONTHLY = 3] = "MONTHLY", E[E.YEARLY = 4] = "YEARLY", E))(a || {});
class O {
  constructor(r, e, I) {
    this.success = r, this.error = e, this.value = I, Object.freeze(this);
  }
  getValue() {
    if (!this.success)
      throw new Error("Cant retrieve the value from a failed result.");
    return this.value;
  }
  getError() {
    if (this.success)
      throw new Error("Cant retrieve the error from a successful result.");
    return this.error;
  }
  static ok(r) {
    return new O(!0, void 0, r);
  }
  static fail(r, e = "") {
    return new O(!1, new T(r, e));
  }
  static error(r) {
    return new O(!1, r);
  }
}
var n = /* @__PURE__ */ ((E) => (E.ACTIVE_STREAMS = "active_streams", E.ADMIN = "admin", E.DEVELOPER = "developer", E.EVERYONE_ANON = "everyone_anon", E.EVERYONE_AUTHED = "everyone_authed", E.GUEST = "guest", E.HOST = "host", E.PRODUCER = "producer", E.ROOMBOT = "roombot", E.SESSION_INFO_VIEWER = "session_info_viewer", E.SESSION_OWNER = "session_owner", E.SESSION_PRODUCER = "session_producer", E.VIEWER = "viewer", E.USER_MANAGER = "user_manager", E))(n || {});
const m = {
  active_streams: !0,
  guest: !0,
  host: !0,
  session_info_viewer: !0,
  session_producer: !0,
  viewer: !0
};
var L = /* @__PURE__ */ ((E) => (E.PartyMode = "party-mode", E.QueueMode = "queue-mode", E))(L || {}), c = /* @__PURE__ */ ((E) => (E.CANCELLED = "cancelled", E.SCHEDULED = "scheduled", E.IN_SESSION = "active", E.ENDED = "ended", E.ARCHIVED = "archived", E))(c || {}), i = /* @__PURE__ */ ((E) => (E.IN_REVIEW = "inReview", E.GRANTED = "granted", E.IGNORED = "ignored", E.DENIED = "denied", E))(i || {}), s = /* @__PURE__ */ ((E) => (E.Google = "google", E))(s || {});
const M = {
  [_.TWITTER]: R.twitter,
  [_.LINKEDIN]: R.linkedin,
  [_.INSTAGRAM]: R.instagram,
  [_.FACEBOOK]: R.facebook,
  [_.YOUTUBE]: R.youtube,
  [_.TIKTOK]: R.tiktok,
  [_.REDDIT]: R.reddit,
  email: { name: "email", color: "#DDDDDD", secondaryColor: "#1E2431" },
  website: { name: "website", color: "#DDDDDD", secondaryColor: "#1E2431" }
}, G = Object.keys(M), u = {
  [_.FACEBOOK]: ({ url: E = "" } = {}) => `//www.facebook.com/sharer.php?u=${encodeURIComponent(E)}`,
  [_.LINKEDIN]: ({ url: E = "" } = {}) => `//www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(E)}`,
  [_.WHATSAPP]: ({ text: E = "" } = {}) => `//wa.me/?text=${encodeURIComponent(E)}`,
  [_.TWITTER]: ({ text: E = "" } = {}) => `//twitter.com/intent/tweet?text=${encodeURIComponent(
    E
  )}&hashtags=waitroom`
};
var F = /* @__PURE__ */ ((E) => (E[E.NONE = 0] = "NONE", E[E.DISABLED = 1] = "DISABLED", E[E.ENABLED = 2] = "ENABLED", E))(F || {});
const K = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null
}, Symbol.toStringTag, { value: "Module" })), v = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null
}, Symbol.toStringTag, { value: "Module" }));
var d = /* @__PURE__ */ ((E) => (E[E.MALE = 0] = "MALE", E[E.FEMALE = 1] = "FEMALE", E[E.OTHER = 2] = "OTHER", E))(d || {});
const w = {
  PLAYER: "PLAYER",
  BROWSER_SUPPORT: "BROWSER_SUPPORT",
  NOTIFICATIONS: "NOTIFICATIONS",
  STREAM_SETTINGS: "STREAM_SETTINGS",
  USER_SETTINGS: "USER_SETTINGS",
  DEVICE_SETTINGS: "DEVICE_SETTINGS",
  DEVICES: "DEVICES",
  TEST_CAM_MIC: "TEST_CAM_MIC",
  TOKEN: "PHOENIX_TOKEN",
  REFRESH_TOKEN: "PHOENIX_REFRESH_TOKEN",
  SOCKET_DOMAIN: "PHOENIX_SOCKET_DOMAIN",
  AGENT_ID: "PHOENIX_AGENT_ID",
  SESSIONS_STARTED: "SESSIONS_STARTED",
  ONBOARD_VIRTUAL_AGENTS: "ONBOARD_VIRTUAL_AGENTS"
};
export {
  T as AppError,
  o as AuthType,
  _ as Brand,
  R as Brands,
  l as CMS,
  S as ClockStatus,
  U as ErrorCodes,
  d as Gender,
  P as LiveStreamProvider,
  L as MeetingType,
  A as MessageBroadcastType,
  N as Orientation,
  C as QueueStatus,
  D as RecordingStatus,
  a as Recurring,
  O as Result,
  n as Role,
  i as SessionAccessRuleStatus,
  s as SessionCalendarType,
  c as SessionStatus,
  u as Share,
  G as SocialKeys,
  M as Socials,
  F as Status,
  K as TrixtaActions,
  v as TrixtaReactions,
  t as VideoFileExtension,
  m as rolesWithId,
  w as storageKeys
};
