import { ReactNode } from 'react';

export const logoIcon = (color = '#1E2431', animate = false): ReactNode => (
  <>
    {animate ? (
      <style>
        {`
        @keyframes animate-fill {
          0% { fill: ${color}; }
          5% { fill: ${color}; }
          43% { fill: transparent; }
          57% { fill: transparent; }
          95% { fill: ${color}; }
          100% { fill: ${color}; }
        }
        .p0 { animation: animate-fill 3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.2s both infinite; }
        .p1 { animation: animate-fill 3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.3s both infinite; }
        .p2 { animation: animate-fill 3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.4s both infinite; }
        .p3 { animation: animate-fill 3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.5s both infinite; }
        .p4 { animation: animate-fill 3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6001s both infinite; }
      `}
      </style>
    ) : null}
    <g>
      <g>
        <path
          className="p0"
          fill={color}
          fillOpacity="0.3"
          d="M17.6,19.5l3.2-16.4L26.7,20l-3.2,16.4L17.6,19.5z"
        />
        <path
          className="p1"
          fill={color}
          fillOpacity="0.3"
          d="M38.8,19.5L42,3.1L47.9,20l-3.2,16.4L38.8,19.5z"
        />
        <path className="p2" fill={color} d="M0,0h9.2l13.5,39h-9.2L0,0z" />
        <path className="p3" fill={color} d="M21.2,0h9.2L44,39h-9.2L21.2,0z" />
        <path className="p4" fill={color} d="M64,15.9L42.5,0L56,39L64,15.9z" />
      </g>
    </g>
  </>
);

export const logo = (textColor = '#1E2431', color = '#1E2431'): ReactNode => (
  <g>
    <g>
      <path
        fill={textColor}
        d="M111.8,32.1c2,0,3.8-0.7,5.2-2v1.7h4.3v-16H117v1.7c-1.3-1.3-3.2-2-5.2-2c-4.6,0-8.2,3.6-8.2,8.3
			C103.6,28.5,107.2,32.1,111.8,32.1z M112.6,28.4c-2.6,0-4.6-1.9-4.6-4.6s2.1-4.6,4.6-4.6c2.6,0,4.6,1.9,4.6,4.6
			S115.1,28.4,112.6,28.4z"
      />
      <path
        fill={textColor}
        d="M127.2,13.3c1.3,0,2.5-1.1,2.5-2.5s-1.1-2.5-2.5-2.5c-1.4,0-2.5,1.1-2.5,2.5S125.9,13.3,127.2,13.3z
			 M129.4,31.8v-16h-4.3v16H129.4z"
      />
      <path
        fill={textColor}
        d="M142.5,15.8h-3.7v-4.4h-4.3v4.4H132v3.4h2.5v7.6c0,3.2,2,5,5.2,5h2.7v-3.6h-1.6c-1.2,0-2-0.7-2-2.2v-6.9h3.7
			L142.5,15.8L142.5,15.8z"
      />
      <path
        fill={textColor}
        d="M149.6,15.8h-4.1v16h4.3v-7.2c0-3.6,2-5,5.3-5v-4.1c-2.8,0.2-4.7,1.7-5.5,3.2V15.8z"
      />
      <path
        fill={textColor}
        d="M165.6,32.1c5.1,0,8.9-3.5,8.9-8.3s-3.8-8.3-8.9-8.3c-5.2,0-9,3.5-9,8.3S160.4,32.1,165.6,32.1z M165.6,28.4
			c-2.6,0-4.6-1.9-4.6-4.6s2-4.6,4.6-4.6c2.5,0,4.6,1.9,4.6,4.6C170.2,26.5,168.1,28.4,165.6,28.4z"
      />
      <path
        fill={textColor}
        d="M185.8,32.1c5.1,0,8.9-3.5,8.9-8.3s-3.8-8.3-8.9-8.3c-5.2,0-9,3.5-9,8.3S180.6,32.1,185.8,32.1z M185.8,28.4
			c-2.6,0-4.6-1.9-4.6-4.6s2-4.6,4.6-4.6c2.5,0,4.6,1.9,4.6,4.6C190.4,26.5,188.3,28.4,185.8,28.4z"
      />
      <path
        fill={textColor}
        d="M95,26.1l-3.7-10.2H88l-3.7,10.2L81,15.9h-4.6l5.6,16h3.9l3.6-10.6l3.6,10.6H97l5.6-16H98L95,26.1z"
      />
      <path
        fill={textColor}
        d="M213.7,21.6l-3.6,10.2h-3.3l-3.7-10.2l-3.3,10.2h-4.6l5.6-16h3.9l3.6,10.6l3.6-10.6h3.9l5.6,16H217
			L213.7,21.6z"
      />
    </g>
    <g>
      <g>
        <path
          fill={color}
          fillOpacity="0.3"
          d="M17.8,19.5L21,3.1L26.9,20l-3.2,16.4L17.8,19.5z"
        />
        <path
          fill={color}
          fillOpacity="0.3"
          d="M39,19.5l3.2-16.4L48.1,20l-3.2,16.4L39,19.5z"
        />
        <path fill={color} d="M0.2,0h9.2l13.5,39h-9.2L0.2,0z" />
        <path fill={color} d="M21.4,0h9.2l13.6,39H35L21.4,0z" />
        <path fill={color} d="M64.2,15.9L42.7,0l13.5,39L64.2,15.9z" />
      </g>
    </g>
  </g>
);
