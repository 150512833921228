import {
  configureStore,
  EnhancedStore,
  getDefaultMiddleware,
  Middleware,
} from '@reduxjs/toolkit';
import { commonMiddlewares, CommonState, ModalState } from '@waitroom/common';
import { CommonTrixtaState } from '@waitroom/common-trixta';
import { Reducer } from 'redux';
import dynamicMiddlewares from 'redux-dynamic-middlewares';
import { createInjectorsEnhancer } from 'redux-injectors';
import createSagaMiddleware from 'redux-saga';
import { config } from '../config';
import { createReducer } from './reducers';

export function configureAppStore(
  initialState: Partial<RootState> = {},
  reducers?: Record<string, Reducer>,
): EnhancedStore {
  const reduxSagaMonitorOptions = {};
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const { run: runSaga } = sagaMiddleware;

  // Create the store with saga middleware
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const middlewares: Middleware<any>[] = [sagaMiddleware, ...commonMiddlewares];

  // temporary fix for type missmatch
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const enhancers: any[] = [
    createInjectorsEnhancer({
      createReducer,
      runSaga,
    }),
  ];

  const store = configureStore({
    preloadedState: initialState,
    reducer: createReducer(reducers),
    middleware: [
      ...getDefaultMiddleware({
        thunk: false,
        immutableCheck: {
          ignore: ['socket', 'channel', 'trixta', 'phoenix', 'router'],
        },
        serializableCheck: false,
      }),
      dynamicMiddlewares,
      ...middlewares,
    ],
    devTools: !config.isProd,
    enhancers,
  });

  return store;
}

const store = configureAppStore();
export type AppDispatch = typeof store.dispatch;
export interface RootState extends CommonState, CommonTrixtaState {
  modal: ModalState;
}

export default store;
