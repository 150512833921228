import { commonTranslations } from '@waitroom/common';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import { initReactI18next } from 'react-i18next';
import { config } from '../config';
import auth from './en/auth.json';
import error from './en/error.json';
import form from './en/form.json';
import en from './en/translation.json';

export const translationsJson = {
  en: {
    translation: {
      ...en,
      auth,
      form,
      error,
      ...commonTranslations.en,
    },
  },
};

export const i18n = i18next
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  .use(intervalPlural)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: translationsJson,
    fallbackLng: 'en',
    debug: config.environment !== 'production' && config.environment !== 'test',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });
