import { config as commonConfig } from '@waitroom/config';
import { FeatureFlags } from '@waitroom/models';
import { parseJson, toBoolean } from '@waitroom/utils';
import pkg from '../../package.json';
import { envs } from '../services/envs';
import type { Environment } from './types';

const baseUrl = envs.VITE_BASE_URL || window.location.origin;
const mars = String(`https://${envs.VITE_MARS_DOMAIN}`);
const luxor = String(`https://${envs.VITE_LUXOR_DOMAIN}`);
const punjab = String(`https://${envs.VITE_PUNJAB_DOMAIN}`);
const wormhole = String(`https://${envs.VITE_WORMHOLE_DOMAIN}`);

const env = (envs.NODE_ENV || 'production') as Environment;
const customEnv = (envs.VITE_ENVIRONMENT || env) as Environment;
const baseConfig = {
  environment: env,
  customEnv: customEnv,
  isProd: customEnv === 'production',
  isDev: customEnv === 'development',
  version: pkg.version,
  dateTime: {
    timezone: {
      default: 'America/New_York',
      current: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  },
  agora: {
    id: envs.VITE_AGORA_APP_ID,
    logLevel: envs.VITE_AGORA_LOG_LEVEL,
    token: `${wormhole}/v1.0/agora/token`,
    wasmRoot: `https://agora-apps-staging.s3.amazonaws.com`,
  },
  baseUrl,
  metaData: {
    imageURL: `${baseUrl}/assets/images/meta/og-image.jpg`,
  },
  google: {
    clientId: envs.VITE_GOOGLE_CLIENT_ID,
    recaptcha: {
      key: envs.VITE_GOOGLE_RECAPTCHA_KEY,
      enabled:
        envs.VITE_GOOGLE_RECAPTCHA_KEY &&
        toBoolean(envs.VITE_USE_GOOGLE_RECAPTCHA),
    },
  },
  apple: {
    clientId: envs.VITE_APPLE_CLIENT_ID,
  },
  cookieConsent: {
    id: 'CookieConsent',
    expiration: 356, // 1 year
    dismiss: 7, // 7 days
  },
  trixta: {
    domain: String(envs.VITE_TRIXTA_DOMAIN),
  },
  domains: {
    default: mars,
    luxor,
    punjab,
    wormhole,
    cdn:
      envs.VITE_CDN_DOMAIN ||
      'https://s3.us-east-1.amazonaws.com/annabelle-develop',
    s3: {
      default: 'https://waitroom-web-app.s3.amazonaws.com',
      image:
        envs.VITE_S3_IMAGE_DOMAIN ||
        'https://s3.us-east-1.amazonaws.com/annabelle-develop',
    },
  },
  typeform: {
    ids: {
      general: envs.VITE_TYPEFORM_SURVEY_ID || 'ftnTEeja',
      earlyAccess: 'gZ4Tb3Yf',
    },
  },
  featureFlags: parseJson<FeatureFlags>(envs.VITE_FEATURE_FLAGS || '{}') || {},
  session: {
    enableRecordingDefault: toBoolean(
      envs.VITE_SESSION_ENABLE_RECORDING_DEFAULT,
    ),
    maxSpeakers: Number(envs.VITE_MAX_SPEAKERS || 20),
    minSpeakers: 1,
  },
  slack: {
    authUrl: 'https://slack.com/oauth/v2/authorize',
    id: envs.VITE_SLACK_ID || '1137653370112.4710051368369', // prod
  },
};

// merge configs
// ! does not do a deep merge
export const config = {
  ...commonConfig,
  ...baseConfig,
};
