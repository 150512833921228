/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { errorService } from '@waitroom/error-service';
import { toBoolean } from '@waitroom/utils';
import { config } from '../../config';
import { envs } from '../envs';

const domain = envs.VITE_SENTRY_DSN;
const isEnabled = toBoolean(envs.VITE_SENTRY_ENABLED);
const enabled = isEnabled && domain;

export const sentryService = {
  enabled,
  init: (): void => {
    if (enabled) {
      Sentry.init({
        dsn: domain,
        release: `waitroom@${config.version}`,
        environment: config.environment,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
      });
    }
    errorService.init({
      console: config.environment !== 'production',
      log: enabled
        ? (err: unknown): void => {
            if (!err) return;
            if (err instanceof Error) {
              Sentry.captureException(err);
              return;
            }
            if (typeof err === 'object') {
              const { error, message } = err as any;
              Sentry.captureException(
                new Error(error || message || JSON.stringify(err)),
              );
              return;
            }
            Sentry.captureException(new Error(String(err)));
          }
        : undefined,
    });
  },
};
