// ! should not contain any external libraries or chakra styles
// ! as this loader is being loaded before those libraries
import type {
  CSSProperties,
  HTMLAttributes,
  ReactElement,
  ReactNode,
} from 'react';
import { logoIcon } from '../../Logo/icons';

const wrapperStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
} as const;
const childStyles = {
  padding: '2.6rem 2rem',
  backgroundColor: 'rgba(0, 0, 0, 0.08)',
  borderRadius: '2rem',
  lineHeight: 0,
};

export interface PageLoaderProps {
  containerStyles?: CSSProperties;
  logoProps?: HTMLAttributes<SVGElement>;
  children?: ReactNode;
}
const PageLoader = ({
  children,
  logoProps,
  containerStyles,
  ...rest
}: PageLoaderProps): ReactElement | null => (
  <div style={{ ...containerStyles, ...wrapperStyles }} {...rest}>
    <div style={childStyles}>
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={60}
          height={36.5} // 39 / (64 / 60)
          viewBox="0 0 64 39"
          {...logoProps}
        >
          {logoIcon(undefined, true)}
        </svg>
      </div>
    </div>
    {children}
  </div>
);

export const fullscreenLoader = (
  <PageLoader
    containerStyles={{
      position: 'fixed',
      top: '0',
      left: '0',
      zIndex: 1400,
      backgroundColor: 'white',
    }}
  />
);

export default PageLoader;
