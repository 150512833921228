const t = {
  "gray-900-90": "rgba(30, 36, 49, 0.9)",
  "gray-900-50": "rgba(30, 36, 49, 0.5)",
  "gray-900-30": "rgba(30, 36, 49, 0.3)",
  "gray-800-90": "rgba(76, 83, 99, 0.9)",
  "gray-800-50": "rgba(76, 83, 99, 0.5)",
  "white-80": "rgba(255, 255, 255, 0.80)",
  "white-65": "rgba(255, 255, 255, 0.65)",
  "white-30": "rgba(255, 255, 255, 0.30)",
  "gold-20": "rgba(214, 203, 158, 0.2)",
  "gold-50": "rgba(214, 203, 158, 0.5)",
  "gold-70": "rgba(214, 203, 158, 0.7)"
}, a = {
  gray: {
    50: "#F2F1F6",
    100: "#E7E7ED",
    200: "#D5D5DC",
    300: "#BDBFC7",
    400: "#9DA2AC",
    500: "#888E99",
    600: "#7B818C",
    700: "#5F6674",
    800: "#4C5363",
    900: "#1E2431",
    1e3: "#11141A",
    1100: "#0A0C0F"
  },
  red: {
    50: "#FFF7F3",
    100: "#FEF1ED",
    200: "#FDDCD5",
    300: "#FBCCC5",
    400: "#F9B6AE",
    500: "#F8A7A0",
    600: "#F68A8E",
    700: "#F0555E",
    800: "#DE3355",
    900: "#CC104B",
    1e3: "#C40843"
  },
  orange: {
    50: "#FFF9EA",
    100: "#FFF6E5",
    200: "#FFEED8",
    300: "#FFE4C9",
    400: "#FFD8B6",
    500: "#FFD0A8",
    600: "#FFB894",
    700: "#FF9667",
    800: "#FC7246",
    900: "#F05828",
    1e3: "#E34B1B"
  },
  green: {
    50: "#F5FEF9",
    100: "#F3FDF6",
    200: "#E5F5E4",
    300: "#DDF1D9",
    400: "#C9EACC",
    500: "#B2DCB3",
    600: "#81C594",
    700: "#67B678",
    800: "#49A47E",
    900: "#35846B",
    1e3: "#2F7A62"
  },
  gold: "#c9b17f",
  avatar: {
    0: "#E09489",
    1: "#E2B37E",
    2: "#E2C495",
    3: "#D9A8A3",
    4: "#B4C8CC"
  },
  gradient: {
    red: "linear-gradient(135deg, #F69396 15%, #F0555E 45%, #DE3355 85%)",
    green: "linear-gradient(135deg, #C7E9CB 15%, #49A47E 45%, #35846B 85%)",
    gold: "linear-gradient(135deg, #E8DEB4 0%, #C1A875 70%)"
  },
  transparent: t,
  t
}, o = "https://waitroom.notion.site", e = `${o}/The-Waitroom-Experience-e859705ac3284030b05d20b0d2b2b74c`, i = {
  addEvent: {
    clientId: "abdoZUKFhzMHguCGYmFw96558",
    endpoint: "https://www.addevent.com/dir/"
  },
  routes: {
    home: "/",
    host: "/host",
    notFound: "/404"
  },
  facebook: {
    appId: ""
  },
  company: {
    name: "Waitroom",
    domain: "waitroom.com",
    emails: {
      default: "info@waitroom.com",
      contact: "hello@waitroom.com",
      support: "support@waitroom.com",
      deleteAccount: "deleteaccount@waitroom.com",
      community: "community@waitroom.com"
    },
    socials: {
      twitter: "https://twitter.com/waitroom",
      youtube: "https://www.youtube.com/channel/UCBjV1ixzr5jfrCHECpNbL4w",
      linkedin: "https://www.linkedin.com/company/waitroom/",
      instagram: "https://instagram.com/waitroom"
    }
  },
  maxImageSize: 5242880,
  // 5MB
  links: {
    notion: o,
    onboarding: "https://calendly.com/sean-waitroom",
    googleCalendar: "https://workspace.google.com/u/0/marketplace/app/waitroom/732264686334",
    knowledgebase: {
      default: `${o}/Waitroom-Knowledgebase-2c2fb04d0aba418987c553813d041fb6`,
      guides: `${o}/Waitroom-User-Guides-aca948b83116497194be5f9eef55d6e3?pvs=4`,
      communityGuidelines: `${o}/Waitroom-Community-Guidelines-2dcd534b589d4f6390db8206f3c63b46`,
      liveStreaming: `${o}/Live-stream-to-multiple-platforms-3bb9103e096a4cc69332e47b112dca8c`,
      hostsInfo: `${o}/Guide-to-Hosting-on-Waitroom-1157fe7389b24c57ab40e3b8f0cae0f0`,
      acknowledgements: `${o}/Acknowledgements-583bf312c0574df7840bf5327247a53e`,
      gettingStarted: `${o}/What-to-expect-when-hosting-your-first-Waitroom-59ca069525f0430d8aee2bd1fff5871b`,
      privateSessions: `${o}/Private-sessions-on-Waitroom-97a6490c58f6415a90d7fe00db6403b6`,
      experience: e,
      producerSafePlace: `${e}#be7c0efb471f40af9340c642eaa4ff08`,
      timeWithHost: `${e}#0705f6c63118414d95767181ba8a55e3`,
      timeAndQueue: `${e}#3b8b16cde2ed4388b54c58b6e5fd7e8a`,
      chatClipping: `${e}#59b3bd0d283e4ab987e2c7f907f5b504`
    },
    terms: "https://waitroom.com/landing/terms",
    privacy: "https://waitroom.com/landing/privacy",
    careers: "https://waitroom.com/landing/careers",
    feedback: "https://waitroom.typeform.com/to/fYTMc7N0",
    chrome: "https://www.google.com/chrome/",
    firefox: "https://www.mozilla.org/en-US/firefox/new/",
    edge: "https://www.microsoft.com/en-us/edge",
    apps: {
      googlePlay: "https://play.google.com/store/apps/details?id=com.waitroom",
      appleApp: "https://apps.apple.com/us/app/waitroom/id1561696428"
    }
  },
  sanity: {
    appId: "p1n4xy2d",
    apiUrl: "https://p1n4xy2d.api.sanity.io/v2023-01-19/data/query/production",
    cdnUrl: "https://cdn.sanity.io"
  }
};
export {
  a as colors,
  i as config
};
