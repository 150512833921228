import { httpService as s } from "@waitroom/http-client";
let c;
const v = (e) => {
  c = {
    ...c,
    ...e
  };
}, u = async (e) => typeof e == "function" ? await e() : await e, d = async () => {
  const e = await u(c.getAuthToken);
  return {
    Authorization: e ? `Bearer ${e}` : void 0
  };
}, p = async () => {
  const [e, a] = await Promise.allSettled([
    u(c.getFingerPrint),
    u(c.getClientDetails)
  ]);
  return {
    "X-Fingerprint": e.status === "fulfilled" ? e.value : "",
    "X-Client-Info": JSON.stringify(
      a.status === "fulfilled" ? a.value : {}
    )
  };
}, l = (e, a) => `${e || c.baseURL}/${a || c.version}`, g = (e) => async (a, { version: i, baseURL: o, headers: y, ...w } = {}) => ({
  ...w,
  url: a,
  baseURL: l(o, i),
  headers: {
    ...await p(),
    ...e ? await d() : {},
    ...y
  }
}), n = g(!1), t = g(!0), A = {
  verifyOTP: async (e) => s.post(await n("/auth/verify-otp", e)),
  verifyOAuthOTP: async (e) => s.post(await n("/oauth/verify-otp", e)),
  requestOTP: async (e) => s.post(await n("/auth/request-otp", e)),
  requestOAuthOTP: async (e) => s.post(await n("/oauth/request-otp", e)),
  verifyAuthTokenProvider: async (e) => s.post(await n("/auth/social-token", e)),
  verifyOAuthTokenProvider: async (e) => s.post(await n("/oauth/social-token", e)),
  verifyAuthCodeProvider: async (e) => s.post(await n("/auth/social-code", e)),
  verifyOAuthCodeProvider: async (e) => s.post(await n("/oauth/social-code", e)),
  refreshToken: async (e) => s.put(await n("/auth/refresh-auth-token", e)),
  logout: async (e) => s.post(await t("/auth/logout", e)),
  hostOptIn: async (e) => s.patch(
    await t("/auth/user/host-opt-in", e)
  ),
  user: async (e) => s.get(await t("/auth/user", e))
}, $ = {
  getPresignedUrl: async (e) => s.get(
    await t("/image-uploads/presigned-url", e)
  )
}, f = {
  getStatus: async (e, a, i) => s.get(
    await t(
      `/recordings/${e}/recurrence/${a}/status`,
      { ...i, version: "v2.0" }
    )
  ),
  getRecording: async (e, a, i) => s.get(
    await t(
      `/recordings/${e}/recurrence/${a}/recording`,
      { ...i, version: "v2.0" }
    )
  ),
  getPeople: async (e, a, i) => s.get(
    await t(
      `/recordings/${e}/recurrence/${a}/people`,
      { ...i, version: "v2.0" }
    )
  ),
  getEpisodes: async (e, a) => s.get(
    await t(`/recordings/${e}/episodes`, {
      ...a,
      version: "v2.0"
    })
  ),
  getSessionRecordings: async (e, a, i) => s.get(
    await t(
      `/recordings/by-session/${e}/${a}`,
      i
    )
  ),
  getLatestPlayable: async (e) => s.get(
    await t("/recordings/latest-playable", e)
  )
}, b = {
  getById: async (e, a) => s.get(await t(`/sessions/${e}`, a)),
  getFeatured: async (e) => s.get(await n("/sessions/featured", e)),
  subscribe: async (e) => s.post(
    await t("/sessions/subscribe", e)
  ),
  unsubscribe: async (e) => s.post(
    await t("/sessions/unsubscribe", e)
  ),
  getCategories: async (e) => s.get(await t("/sessions/category", e)),
  create: async (e) => s.post(await t("/sessions", e)),
  update: async (e, a, i) => s.patch(
    await t(
      `/sessions/${e}/recurrence/${a}`,
      i
    )
  ),
  delete: async (e, a, i) => s.patch(
    await t(
      `sessions/${e}/recurrence/${a}`,
      i
    )
  ),
  getPast: async (e) => s.get(
    await t("/sessions/get-past-sessions", e)
  ),
  getFuture: async (e) => s.get(
    await t("/sessions/get-future-sessions", e)
  ),
  restartSession: async (e, a, i) => s.patch(
    await t(
      `/sessions/${e}/recurrence/${a}/recur`,
      i
    )
  ),
  getSessionsLibraryHistory: async (e) => s.get(
    await t("/sessions/library/history", e)
  ),
  getSessionsLibraryUpcoming: async (e) => s.get(
    await t("/sessions/library/upcoming", e)
  ),
  getAiFeed: async (e, a, i) => s.get(
    await t("/ai-feed", {
      ...i,
      params: {
        ...i == null ? void 0 : i.params,
        sessionID: e,
        sessionRecurrenceID: a
      }
    })
  ),
  getAiSummary: async (e, a, i) => s.get(
    await t(
      `/post-session-summaries/by-session-ids/${e}`,
      {
        ...i,
        params: {
          formats: "md",
          ...i == null ? void 0 : i.params,
          sessionRecurrenceID: a
        }
      }
    )
  )
}, r = "/sessions/access", k = {
  inReview: async (e) => s.get(await t(`${r}/in-review`, e)),
  getViewer: async (e) => s.get(await t(`${r}/viewer`, e)),
  getViewerAccessStats: async (e) => s.get(
    await t(`${r}/get-viewer-access-stats`, e)
  ),
  getInReviewViewerAccessStats: async (e) => s.post(
    await t(
      `${r}/get-in-review-access-requests-count`,
      e
    )
  ),
  grantAccess: async (e) => s.put(await t(`${r}/request`, e)),
  add: async (e) => s.put(await t(`${r}/add`, e)),
  remove: async (e) => s.delete(await t(`${r}/remove`, e))
}, S = {
  acquireAuthToken: async (e) => s.post(
    await t("/slack/acquire-auth-token", e)
  ),
  channels: async (e) => s.get(await t("/slack/channels", e)),
  isAuthed: async (e) => s.get(await t("/slack/is-authed", e))
}, P = {
  getByUserId: async (e, a, i, o = "audience") => s.post(
    await t("/agora/token", {
      data: {
        userId: a,
        sessionId: e,
        role: o,
        expirationTs: i,
        tokenType: "userAccount"
      }
    })
  ),
  getByUid: async (e, a, i, o = "audience") => s.post(
    await t("/agora/token", {
      data: {
        sessionId: e,
        uid: a,
        role: o,
        expirationTs: i,
        tokenType: "uid"
      }
    })
  )
}, m = {
  getCurrent: async (e) => s.get(await t("/users/me", e)),
  update: async (e, { data: a, ...i }) => s.put(
    await t(`/users/id/${e}`, {
      ...i,
      data: {
        userData: a
      }
    })
  )
};
export {
  A as authApiService,
  c as config,
  $ as imageApiService,
  v as initConfig,
  f as recordingApiService,
  k as sessionAccessApiService,
  b as sessionApiService,
  S as slackApiService,
  P as tokenApiService,
  m as userApiService
};
